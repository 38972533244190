import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGapple = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 11 12'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M8.42202 11.508C7.77162 12.1392 7.05402 12.0408 6.37002 11.7432C5.64282 11.4396 4.97802 11.4204 4.21002 11.7432C3.25363 12.156 2.74603 12.036 2.17003 11.508C-1.08197 8.15998 -0.601967 3.05999 3.09403 2.86799C3.99042 2.91599 4.61802 3.36359 5.14602 3.40079C5.93082 3.24119 6.68202 2.78399 7.52202 2.84399C8.53122 2.92559 9.28602 3.32399 9.79001 4.04039C7.71402 5.28838 8.20602 8.02438 10.1128 8.79237C9.73121 9.79437 9.24162 10.7844 8.42082 11.5164L8.42202 11.508ZM5.07402 2.83199C4.97682 1.344 6.18282 0.12 7.57002 0C7.76082 1.716 6.01002 2.99999 5.07402 2.83199Z' />
    </svg>
  )
}

export default SVGapple
