import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGgoogle = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M6.1196 5.14237V7.2H9.52272C9.38435 8.0835 8.49372 9.7875 6.1196 9.7875C4.07097 9.7875 2.39997 8.09062 2.39997 6C2.39997 3.90938 4.07097 2.2125 6.1196 2.2125C7.28435 2.2125 8.0651 2.70938 8.51022 3.13838L10.1392 1.5705C9.09372 0.591 7.73922 0 6.1196 0C2.8031 0 0.119598 2.6835 0.119598 6C0.119598 9.3165 2.8031 12 6.1196 12C9.58122 12 11.8807 9.56475 11.8807 6.13837C11.8807 5.74462 11.8383 5.44462 11.787 5.14462L6.1196 5.14237Z' />
    </svg>
  )
}

export default SVGgoogle
