import { IUser, UserContext } from '@context/UserContext'
import React, { useContext } from 'react'
import SVGapple from '@fractal/primitives/SVGs/socials/apple'
import SVGfacebook from '@fractal/primitives/SVGs/socials/facebook'
import SVGgoogle from '@fractal/primitives/SVGs/socials/google'
import trackAccount from 'app/components/Snowplow/trackAccount'
import { useAccountContext } from '../Account.context'

interface ISocialLogin {
  provider: 'google' | 'facebook' | 'apple'
  loginWithText?: string
}

function SocialLogin({ provider, loginWithText, ...props }: ISocialLogin) {
  const { socialLogin, updateAccountModal } = useContext(UserContext)
  const { currentPage } = useAccountContext()

  const config = {
    google: { provider: 'google-oauth2', name: 'Google' },
    facebook: { provider: 'facebook', name: 'Facebook' },
    apple: { provider: 'apple', name: 'Apple' },
  }[provider]

  const resquestAuthentication = () => {
    let eventType: string
    if (currentPage === 'signup') {
      eventType = 'account_creation'
    }
    if (currentPage === 'signin') {
      eventType = 'account_login'
    }
    socialLogin?.(config.provider).subscribe({
      next: (user: IUser) => {
        if (!user.is_first_access) {
          updateAccountModal?.(false)
        }
        trackAccount({
          eventType: eventType as any,
          unitName: 'account_modal',
          unitLocation: 'modal',
          signupType: provider,
          email: user.email as string,
          submitSuccess: true,
        })
      },
      error: (err) => {
        console.log('Error', err)
        trackAccount({
          eventType: eventType as any,
          unitName: 'account_modal',
          unitLocation: 'modal',
          signupType: provider,
          email: '',
          submitSuccess: false,
        })
      },
    })
  }

  let Icon = null as any
  if (provider === 'google') {
    Icon = <SVGgoogle className='vicon' width='12' height='12' />
  } else if (provider === 'facebook') {
    Icon = <SVGfacebook className='vicon' width='12' height='12' />
  } else if (provider === 'apple') {
    Icon = <SVGapple className='vicon' width='12' height='12' />
  }

  return (
    <div {...props}>
      <button
        type='button'
        onClick={resquestAuthentication}
        className='Btn Btn--shadow Btn--small flex-sm-justify-center'
      >
        {Icon}
        <span>
          {`${loginWithText} `}
          <b>{config.name}</b>
        </span>
      </button>
    </div>
  )
}

export default SocialLogin
