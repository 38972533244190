import { gql, useQuery } from '@apollo/client'
import { localeSubPathMap } from '@common/utils/language'
import { LanguageContext } from '@context/LanguageContext'
import React, { useContext } from 'react'

export const GetGenericContent = gql`
  query GetGenericContent($slug: String!, $locale: String = "en-US") {
    genericContentCollection(
      where: { slug: $slug }
      limit: 1
      locale: $locale
    ) {
      items {
        slug
        name
        type
        contents
      }
    }
  }
`

type ContentfulLazyLoadProps = {
  slug: string
  children: (componentData: any) => JSX.Element
}

function ContentfulLazyLoad({ slug, children }: ContentfulLazyLoadProps) {
  const { language } = useContext(LanguageContext)
  const { data } = useQuery(GetGenericContent, {
    variables: {
      slug,
      locale: localeSubPathMap.get(language)
    },
    context: {
      clientName: 'contentful'
    },
    skip: !slug || !children
  })

  if (!data?.genericContentCollection?.items?.[0]?.contents) return null

  return <>{children(data.genericContentCollection.items[0].contents)}</>
}

export default ContentfulLazyLoad
