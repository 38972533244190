import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import SocialLogin from '../forms/SocialLogin'
import Signin from '../forms/Signin'
import { useAccountContext } from '../Account.context'
import styles from '../Account.module.scss'

function SigninPage(props) {
  const {
    requestPageChange,
    translation: { signinPage, signupPage },
    config,
  } = useAccountContext()
  const [ref, inView, entry] = useInView({ triggerOnce: false, threshold: 0 })

  useEffect(() => {
    if (inView && entry?.target) {
      ;(entry.target as HTMLElement).focus()
    }
  }, [inView])

  return (
    <div
      {...props}
      data-testid='signin-page'
      className='p-sm-relative p-sm-5 w-sm-full d-sm-flex flex-sm-column p-md-6'
    >
      <div className='d-md-flex flex-md-align-baseline'>
        <h1
          ref={ref}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className='f-sm-6 f-md-7 text-bold text-foreground-darkblue mb-sm-3 mx-md-5'
        >
          {signinPage?.signinTitle}
        </h1>
        {config.signup && (
          <p className='text-regular text-foreground-darkblue f-sm-2 text-left mb-sm-0 flex-sm'>
            {signinPage?.dontHaveAccountText}{' '}
            <button
              type='button'
              onClick={() =>
                requestPageChange('signup', signinPage?.createOneText)
              }
              data-testid='signup-link'
              className={`Btn-link text-foreground-darkblue text-bold text-underline ${styles.pageLink}`}
            >
              {signinPage?.createOneText}
            </button>
          </p>
        )}
      </div>
      <div className='d-sm-flex flex-sm-wrap'>
        {!!config.socialLogin?.length && (
          <div className={styles.columnMd6}>
            <div
              className={`py-sm-5 px-sm-4 mb-sm-3 w-sm-full pt-md-6 pr-md-7 pl-md-4 h-md-full 
              ${styles.socialContainer} ${styles.nonStandaloneSocial}`}
            >
              {config.socialLogin?.map((socialProvider) => (
                <SocialLogin
                  key={socialProvider}
                  provider={socialProvider as any}
                  loginWithText={signinPage?.signupWithText}
                  data-testid={`signin-social-${socialProvider}`}
                />
              ))}
            </div>
          </div>
        )}
        <div
          className={
            config.socialLogin?.length ? styles.columnMd6 : styles.columnMd12
          }
        >
          <Signin />
        </div>
      </div>
      <div className='d-sm-none d-md-block' style={{ marginTop: 'auto' }}>
        <p
          className={`text-foreground-darkblue f-sm-1 text-regular m-sm-0 pt-sm-5 text-md-left ${styles.privacyPolicy}`}
          dangerouslySetInnerHTML={{ __html: signupPage?.bySigningUp || '' }}
        />
      </div>
    </div>
  )
}

export default SigninPage
