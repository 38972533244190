import React, { useEffect } from 'react'
import { useFormControlContext } from '../FormControl'
import { SelectProps, SelectOptionProps } from './Select.interface'

const Select = ({ children, ...props }: SelectProps) => {
  const { updateContext, validationError } = useFormControlContext()

  useEffect(() => {
    updateContext({ control: { ...props } })
  }, [])

  const errorMessageID = (props.id || props.name) + '_error'

  // code bellow is needed because react doesn't remove aria-invalid when false
  // and the css selector we current use, selects the Select[aria-invalid]
  // move the code to StyledSelect when using tokens
  let ariaProps = { 'aria-describedby': errorMessageID }
  if (validationError) {
    ariaProps['aria-invalid'] = true
  }

  return (
    <select
      {...props}
      {...ariaProps}
      className={`form-control ${props.controlSize === 'large' ? 'form-control--tall' : ''} ${props.className}`} 
    >
      {children}
    </select>
  )
}

const Option = ({ children, ...props }: SelectOptionProps) => {
  return <option {...props}>{children}</option>
}

Select.Option = Option

Select.defaultProps = {
  controlSize: 'default'
}

export default Select
