import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGcheck = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 14 13'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M1 7.24L4.42857 11L13 1' stroke='#002938' strokeWidth='2' />
    </svg>
  )
}

export default SVGcheck
