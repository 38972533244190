'use client'

import { Lang } from 'app/i18n'
import { useParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import getGlobalContext from './getGlobalContext.client'
import { IGlobalContext } from './types'

export default function useGlobalContext() {
  const params = useParams()
  const initialGlobalContext = getGlobalContext()

  const [globalContext, setGlobalContext] = useState<IGlobalContext>({
    ...initialGlobalContext,
    locale: params?.lang as Lang,
  })

  useEffect(() => {
    setGlobalContext({
      ...getGlobalContext(),
      locale: params?.lang as Lang,
    })
  }, [params])

  return globalContext
}
