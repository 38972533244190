import { UserContext } from '@context/UserContext'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'
import { Input } from '@fractal/primitives/atoms/forms/Input'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import React, { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAccountContext } from '../Account.context'
import styles from '../Account.module.scss'

const initialForm = {
  email: '',
  emailError: false,
}

function ForgotPassword() {
  const [form, setForm] = useState(initialForm)
  const [timer, setTimer] = useState(0)
  const [pageStatus, setPageStatus] = useState('initial')
  const { requestPassword } = useContext(UserContext)
  const {
    requestPageChange,
    translation: { forgotPasswordPage, signinPage, errorMessage },
    config,
  } = useAccountContext()
  const [ref, inView, entry] = useInView({ triggerOnce: false, threshold: 0 })
  const [refEmailSent, inViewEmailSent, entryEmailSent] = useInView({
    triggerOnce: false,
    threshold: 0,
  })

  useEffect(() => {
    if (inView && entry?.target) {
      ;(entry.target as HTMLElement).focus()
    }
  }, [inView])

  useEffect(() => {
    if (inViewEmailSent && entryEmailSent?.target) {
      ;(entryEmailSent.target as HTMLElement).focus()
    }
  }, [inViewEmailSent])

  const handleChange = ({ target }) => {
    const { name, value } = target
    setForm((updater) => ({ ...updater, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (timer) return

    if (!form.email) {
      setForm({ ...form, emailError: true })
      return
    }

    if (pageStatus === 'email_sent') {
      setTimer(25)
    } else {
      setPageStatus('requesting')
    }

    requestPassword?.(form.email).subscribe({
      error() {
        setPageStatus('error')
      },
      complete() {
        setPageStatus('email_sent')
        setTimer(25)
      },
    })
  }

  useEffect(() => {
    if (!timer) return

    const timeout = setTimeout(() => {
      setTimer(0)
    }, timer * 1000)

    return () => clearTimeout(timeout)
  }, [timer])

  return (
    <>
      {pageStatus !== 'email_sent' && (
        <div>
          <h1
            ref={ref}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className='f-sm-6 f-md-7 text-bold text-foreground-darkblue mb-sm-4'
          >
            {forgotPasswordPage?.forgotPasswordTitle}
          </h1>
          <p className='text-regular text-foreground-darkblue f-sm-3 text-left mb-sm-5'>
            {forgotPasswordPage?.enterYourEmailText}
          </p>
          <form
            id='passwordForm'
            onSubmit={handleSubmit}
            data-testid='forgot-password-form'
            noValidate
            className='py-md-5 px-md-7'
          >
            <FormControl>
              <Label>{forgotPasswordPage?.emailLabel}</Label>
              <Input
                id='recoveryEmail'
                type='email'
                name='email'
                value={form.email}
                onChange={handleChange}
                required
                disabled={pageStatus === 'requesting'}
                controlSize='large'
                data-testid='forgot-password-form-email'
              />
              {form.emailError && (
                <FormError>{errorMessage?.emailRequired}</FormError>
              )}
            </FormControl>
            <div className='d-sm-flex flex-sm-justify-between flex-sm-align-center'>
              <button
                type='submit'
                disabled={pageStatus === 'requesting'}
                data-testid='forgot-password-form-submit'
                className='Btn Btn--shadow'
              >
                {forgotPasswordPage?.submitButtonText}
              </button>

              {config.signin && (
                <button
                  type='button'
                  onClick={() => requestPageChange('signin', 'Log in')}
                  data-testid='forgot-password-signin-link'
                  className={`Btn-link text-foreground-darkblue text-bold text-underline ${styles.pageLink}`}
                >
                  {signinPage?.signinTitle}
                </button>
              )}
            </div>
          </form>
        </div>
      )}

      {pageStatus === 'email_sent' && (
        <div>
          <h1
            ref={refEmailSent}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className='f-sm-6 f-md-7 text-bold text-foreground-darkblue mb-sm-4'
          >
            {forgotPasswordPage?.forgotPasswordTitle}
          </h1>
          <p
            className='text-regular text-foreground-darkblue f-sm-3 text-left mb-sm-5'
            data-testid='forgot-password-success-message'
          >
            {forgotPasswordPage?.checkYourInboxText}
          </p>
          {!timer && (
            <p
              className='text-regular text-foreground-darkblue f-sm-2 text-left mb-sm-5'
              data-testid='forgot-password-resend-email'
            >
              {forgotPasswordPage?.didntGetTheEmailText}{' '}
              <button
                type='button'
                onClick={handleSubmit}
                data-testid='forgot-password-resend-email-link'
                className={`Btn-link text-foreground-darkblue text-bold text-underline ${styles.pageLink}`}
              >
                {forgotPasswordPage?.resendLinkText}
              </button>
            </p>
          )}
          {config.signin && (
            <button
              type='button'
              onClick={() => requestPageChange('signin', 'Log in')}
              className='Btn Btn--shadow'
            >
              {signinPage?.signinTitle}
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default ForgotPassword
