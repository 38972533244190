import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGadd = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M7 0L7 12L5 12L5 -8.42937e-08L7 0Z' />
      <path d='M12 7L0 7L3.43433e-07 5L12 5L12 7Z' />
    </svg>
  )
}

export default SVGadd
