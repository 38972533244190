'use client'

import { CustomStatsigProvider } from 'components/shared/providers'
import AuthContextProvider from '@common/components/UseAuthContext'
import React from 'react'
import { ApolloProvider } from 'app/components/Apollo/ApolloProvider.client'
import { LayoutProvidersProps } from './Providers.types'
import useGlobalContext from '../GlobalContext/useGlobalContext'

function LayoutProviders({ children }: LayoutProvidersProps) {
  const globalContext = useGlobalContext()

  return (
    <ApolloProvider>
      <AuthContextProvider>
        <CustomStatsigProvider
          language={globalContext.locale === 'en' ? 'en' : 'es'}
        >
          {children}
        </CustomStatsigProvider>
      </AuthContextProvider>
    </ApolloProvider>
  )
}

export default LayoutProviders
