import React from 'react'
import { DividerProps } from './Divider.interface'

const margins = {
  none: 0,
  small: 3,
  default: 4,
  large: 5
}

const Divider = ({
  color,
  direction,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight
}: DividerProps) => {
  const colorClass = color ? 'text-static-ochreTint' : 'text-static-grey3'
  const marginClass = margin ? direction === 'horizontal' ? `my-sm-${margins[margin]}` : `mx-sm-${margins[margin]}` : ''
  const marginTopClass = marginTop ? `mt-sm-${margins[marginTop]}` : ''
  const marginBottomClass = marginBottom ? `mb-sm-${margins[marginBottom]}` : ''
  const marginLeftClass = marginLeft ? `ml-sm-${margins[marginLeft]}` : ''
  const marginRightClass = marginRight ? `mr-sm-${margins[marginRight]}` : ''
  const extraClasses = direction === 'horizontal' ? 'border-sm-top w-sm-full' : 'border-sm-left h-sm-full'

  return (
    <hr
      className={`${colorClass} ${marginClass} ${marginTopClass} ${marginBottomClass} ${marginLeftClass} ${marginRightClass} ${extraClasses}`}
    />
  )
}

Divider.defaultProps = {
  direction: 'horizontal',
  margin: 'default'
}

export default Divider
