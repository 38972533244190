import SVGadd from '@fractal/primitives/SVGs/icons/add'
import SVGcheck from '@fractal/primitives/SVGs/icons/check'
import React from 'react'
import { IButtonToggleLink } from './types'
import styles from './Button.module.scss'

function ButtonToggleLink({
  value,
  text,
  isSelected,
  onToggle,
  dataTestid
}: IButtonToggleLink) {
  return (
    <div data-testid={dataTestid} className={styles.buttonToggleLink}>
      <label
        tabIndex={0}
        className={`text-foreground-darkblue f-sm-3 py-sm-4 px-sm-0 d-sm-flex flex-sm-align-center
          flex-sm-justify-start border-sm-bottom border-static-grey3 ${styles.label}`}
        onKeyDown={event => {
          if (event.keyCode === 32) {
            event.preventDefault()
            onToggle({ target: { value } })
          }
        }}
      >
        <input
          className='hidden-sm'
          value={value}
          type='checkbox'
          name='tag'
          onChange={onToggle}
        />
        <div className='pr-sm-3'>
          <div
            className={`d-sm-flex flex-sm-justify-center flex-sm-align-center w-sm-5 h-sm-5 circle-sm
              border-sm-2 border-static-scarletLite ${isSelected ? 'bg-static-scarletLite' : ''}`}
          >
            {!isSelected && <SVGadd width='14' height='13' className='vicon' />}
            {isSelected && <SVGcheck width='12' height='12' />}
          </div>
        </div>
        <span>{text}</span>
      </label>
    </div>
  )
}

export default ButtonToggleLink
