import { UserContext } from '@context/UserContext'
import { useCustomUserData } from '@context/hooks/useCustomUserData'
import { Divider } from '@fractal/primitives/atoms/Divider'
import { Checkbox } from '@fractal/primitives/atoms/forms/Checkbox'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import { Select } from '@fractal/primitives/atoms/forms/Select'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import setUserPreferences from 'services/members/setUserPreferences'
import Cookies from 'universal-cookie'
import trackAccount from 'app/components/Snowplow/trackAccount'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import FilterProvider from 'app/components/Filter/FilterProvider.client'
import FilterButtonToggle from 'app/components/Filter/FilterButtonToggle.client'
import { useAccountContext } from '../Account.context'
import styles from '../Account.module.scss'

const initialForm: {
  classifications: string[]
  classification_data: {
    [key: string]: {
      age?: string
      ed_level?: string
      grade_level?: string
    }
  }
} = {
  classifications: [],
  classification_data: {},
}

export default function PreferencesPage({ preferences }: any) {
  const [customUserData, setCustomUserData] = useCustomUserData()
  const { updateAccountModal, user } = useContext(UserContext)
  const [form, setForm] = useState({
    ...initialForm,
    classifications:
      customUserData?.roles?.map((role) => role.toUpperCase()) || [],
  })
  const {
    translation: { preferencesPage, errorMessage },
  } = useAccountContext()
  const [selectedTags, setSelectedTags] = useState([])
  const [, inView, entry] = useInView({ triggerOnce: false, threshold: 0 })
  const [submitErrorText, setSubmitErrorText] = useState<string>()
  const globalContext = useGlobalContext()

  useEffect(() => {
    if (inView && entry?.target) {
      ;(entry.target as HTMLElement).focus()
    }
  }, [inView])

  const cookies = new Cookies()
  const userToken = cookies.get('user-jwt') || null

  const handleChange = (event) => {
    let { name, value } = event.target

    if (event.target.type === 'checkbox') {
      const upperCaseValue = value.toUpperCase()
      const newForm = { ...form }
      const newValue = event.target.checked
        ? [...form[name], upperCaseValue]
        : [...form[name].filter((item) => item !== upperCaseValue)]

      delete newForm.classification_data[value]

      setForm({
        ...newForm,
        [name]: newValue,
        classification_data: {
          ...newForm.classification_data,
        },
      })
    } else {
      const [firstField, ...fieldKeys] = name.split('.')
      const newValue = form[firstField] ? { ...form[firstField] } : {}
      let currentBase = newValue

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, fieldKey] of fieldKeys.entries()) {
        if (index === fieldKeys.length - 1) {
          currentBase[fieldKey] = value
        } else if (currentBase[fieldKey]) {
          currentBase = currentBase[fieldKey]
        } else {
          currentBase[fieldKey] = {}
          currentBase = currentBase[fieldKey]
        }
      }
      name = firstField
      value = newValue

      setForm({
        ...form,
        [name]: value,
      })
    }
  }

  const handleTagsChange = (event) => {
    setSelectedTags(event)
  }

  const closeModal = () => {
    updateAccountModal?.(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      await setUserPreferences({
        userId: user.id,
        authHeader: userToken,
        tags: selectedTags,
      })
    } catch (e) {
      setSubmitErrorText(
        errorMessage?.preferenceError || errorMessage?.genericError
      )
      return
    }

    let audienceArray = []
    const childsAge = form.classification_data.parent
      ? form.classification_data.parent.age
      : undefined
    const classGradeLevel = form.classification_data.educator
      ? form.classification_data.educator.grade_level
      : undefined

    let age
    if (form.classification_data.individual) {
      age = form.classification_data.individual.age
    }
    if (form.classification_data.other) {
      age = form.classification_data.other.age
    }

    let educationLevel
    if (form.classification_data.individual) {
      educationLevel = form.classification_data.individual.ed_level
    }
    if (form.classification_data.other) {
      educationLevel = form.classification_data.other.ed_level
    }

    if (form.classifications.length) {
      audienceArray = form.classifications.map((item) => item.toLowerCase())
    }

    try {
      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_DOMAIN}/members/v1/users/${user.id}`,
        form
      )
      if (form.classifications.length) {
        setCustomUserData(
          'roles',
          form.classifications.map((item) => item.toLowerCase())
        )
      }
      trackAccount({
        unitName: 'account_preferences',
        unitLocation: 'header',
        eventType: 'account_preferences',
        audience: `[${audienceArray.toString()}]`,
        topic: `[${selectedTags.toString()}]`,
        submitSuccess: true,
        buttonName: 'select_topics',
        childAge: childsAge,
        classGradeLevel,
        age,
        educationLevel,
      } as any)
    } catch (error) {
      trackAccount({
        unitName: 'account_preferences',
        unitLocation: 'header',
        eventType: 'account_preferences',
        audience: `[${audienceArray.toString()}]`,
        topic: `[${selectedTags.toString()}]`,
        submitSuccess: false,
        buttonName: 'select_topics',
        childAge: childsAge,
        classGradeLevel,
        age,
        educationLevel,
      } as any)
      setSubmitErrorText(
        errorMessage?.preferenceError || errorMessage?.genericError
      )
      return
    }

    await axios({
      url: `${process.env.NEXT_PUBLIC_API_DOMAIN}/subscribers/${
        globalContext.locale?.split('-')[0]
      }/subscribe`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: user.email,
        web_user_selected_roles: audienceArray,
        join_source_url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${globalContext.slug}`,
      },
    })

    closeModal()
  }

  return (
    <div
      data-testid='account-preferences-page'
      className={`p-md-6 ${styles.preferencePage}`}
    >
      <h1
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className='f-sm-6 f-md-7 text-bold text-foreground-darkblue mb-sm-3'
      >
        {preferencesPage?.pageTitle}
      </h1>
      <p className='text-regular text-foreground-darkblue f-sm-3 text-left mb-sm-0'>
        {preferencesPage?.pageDescription}
      </p>
      <Divider margin='large' />
      <form onSubmit={handleSubmit}>
        {/* CLASSIFICATIONS */}
        <Label size='large' marginSize='none'>
          {preferencesPage?.classificationsTitle}
        </Label>
        <p className='text-regular text-foreground-darkblue f-sm-2 text-left mb-sm-4'>
          {preferencesPage?.classificationsDescription}
        </p>
        <div className='d-sm-flex flex-sm-wrap'>
          {preferencesPage?.classifications.map((option, index) => (
            <div
              key={`classifications_${option.key}_${option.label}`}
              className={styles.columnMd6}
            >
              <FormControl margin='none'>
                <Label>{option?.label}</Label>
                <Checkbox
                  value={option?.key}
                  checked={form.classifications?.find(
                    (classification) =>
                      classification.toUpperCase() ===
                      option?.key?.toUpperCase()
                  )}
                  name='classifications'
                  onChange={handleChange}
                  data-testid={`account-preferences-page-classifications-${index}`}
                />
              </FormControl>

              {form.classifications?.find(
                (classification) =>
                  classification.toUpperCase() === option?.key?.toUpperCase()
              ) &&
                option?.conditionalFields?.map((field, detailIndex) => (
                  <div
                    key={`${field.key}-${field.label}`}
                    className={styles.conditionalFieldsContainer}
                  >
                    <FormControl margin='large'>
                      <Label>{field.label}</Label>
                      {field.subLabel && (
                        <span className='d-sm-block mt-sm-n2 mb-sm-3 f-sm-1 text-foreground-darkblue'>
                          {field.subLabel}
                        </span>
                      )}
                      <Select
                        onChange={handleChange}
                        name={`classification_data.${option.key}.${field.key}`}
                        data-testid={`account-preferences-page-classifications-${index}-${detailIndex}`}
                      >
                        <Select.Option />
                        {field.values?.map((optionValue) => (
                          <Select.Option
                            value={optionValue.key}
                            key={optionValue.key}
                          >
                            {optionValue.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormControl>
                    {preferencesPage?.classifications &&
                      detailIndex ===
                        (option.conditionalFields?.length || 0) - 1 &&
                      index !== preferencesPage.classifications.length - 1 && (
                        <div className='d-md-none'>
                          <Divider />
                        </div>
                      )}
                  </div>
                ))}
            </div>
          ))}
        </div>
        <Divider margin='large' />

        {/* TOPICS */}
        {!!preferences && !!preferences.length && (
          <div>
            <Label size='large' marginSize='none'>
              {preferencesPage?.tagsTitle}
            </Label>
            <div>
              <FilterProvider unitName='account_preferences'>
                <FilterButtonToggle
                  tags={preferences}
                  selectedTags={[]}
                  visibleTagCount={preferences.length}
                  onButtonToggle={handleTagsChange}
                />
              </FilterProvider>
            </div>
          </div>
        )}

        {submitErrorText && (
          <div className='mt-sm-5'>
            <FormError data-testid='account-preference-form-error'>
              {submitErrorText}
            </FormError>
          </div>
        )}

        {/* STICKY BOTTOM */}
        <div
          className={`p-sm-fixed p-md-relative bottom-sm-0 left-sm-0 w-sm-full d-sm-flex flex-sm-justify-between
          flex-sm-align-center p-sm-4 p-md-0 mt-md-6 bg-background-page-creamLite ${styles.stickySection}`}
        >
          <button type='submit' className='Btn Btn--shadow'>
            {preferencesPage?.saveButton}
          </button>
          <TrackClick
            as='button'
            type='button'
            className={`Btn-link text-foreground-darkblue text-bold text-underline f-sm-2 ${styles.pageLink}`}
            isEngagementEvent={false}
            trackProps={{
              clickType: 'button',
              unitName: 'account_preferences',
              unitLocation: 'header',
              buttonName: 'skip',
              componentId: null,
            }}
            onClick={closeModal}
          >
            {preferencesPage?.skipText}
          </TrackClick>
        </div>
      </form>
    </div>
  )
}
