import { trackPageView as trackPageViewSp } from '@snowplow/browser-tracker'
import getSharedContext from './getSharedContext'

export default function trackPageView() {
  if (typeof window === 'undefined') {
    return
  }

  const sharedContext = getSharedContext({
    unitLocation: null,
    eventHorizontalPosition: window?.scrollX,
    eventVerticalPosition: window?.scrollY,
  })

  trackPageViewSp(sharedContext)
}
