import React from 'react'
import ForgotPassword from '../forms/ForgotPassword'

function ForgotPasswordPage(props) {
  return (
    <div
      {...props}
      className='p-sm-relative p-sm-5 w-sm-full d-sm-flex flex-sm-column p-md-6'
      data-testid='forgot-password-page'
    >
      <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordPage
