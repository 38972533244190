import { IUserContext, UserContext } from '@context/UserContext'
import merge from 'lodash/merge'
import React, { createContext, useContext } from 'react'
import trackClick from 'app/components/Snowplow/trackClick'
import { defaultProps } from './Account.data'
import { IAccount, IAccountConfig } from './types'

export type AccountPage = IUserContext['currentPage']

export interface IAccountContext {
  requestPageChange: (accountPage: AccountPage, linkText?: string) => void
  currentPage: AccountPage
  translation: Omit<IAccount, 'config'>
  config: IAccountConfig
}

export interface IAccountProviderProps {
  accountProps: IAccount
  children: (a: IAccountContext) => React.ReactChild
}

export const AccountContext = createContext<IAccountContext>({
  requestPageChange: () => {},
  currentPage: 'signup',
  translation: { ...defaultProps },
  config: { ...defaultProps.config },
})

export const useAccountContext = () => useContext(AccountContext)

export function UseAccountProvider({
  accountProps,
  children,
}: IAccountProviderProps) {
  const { currentPage, setCurrentPage } = useContext(UserContext)

  // make a deep merge for translation props
  let translationProps = { ...accountProps }
  translationProps = merge(defaultProps, translationProps)

  // make a shallow merge for the configProps
  const configProps = { ...defaultProps.config, ...accountProps.config }

  const requestPageChange = (newPage, linkText) => {
    if (linkText) {
      trackClick({
        clickType: 'button',
        unitName: 'account modal',
        buttonName: linkText,
        unitLocation: 'modal',
        componentId: null,
      })
    }
    setCurrentPage(newPage)
  }

  return (
    <AccountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        requestPageChange,
        currentPage,
        translation: translationProps,
        config: configProps,
      }}
    >
      {children({
        requestPageChange,
        currentPage,
        translation: translationProps,
        config: configProps,
      })}
    </AccountContext.Provider>
  )
}
