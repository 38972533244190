'use client'

import * as React from 'react'
import { IFilterState } from './types'

const DefaultFilterContext: IFilterState = {
  selectedItems: [],
  all: true,
  toggleAll: (): void => {},
  updateSelectedItems: (): void => {},
  unitName: '',
}

const FilterContext = React.createContext(DefaultFilterContext)

const { Provider, Consumer } = FilterContext

export {
  Provider as FilterProvider,
  Consumer as FilterConsumer,
  DefaultFilterContext,
}

export default FilterContext
