'use client'

import {
  ApolloLink,
  DefaultContext,
  HttpLink,
  NextLink,
  Operation,
  from,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr'

function makeClient() {
  const CONTENTFUL_CONTEXT_NAME: string = 'contentful'

  const contentfulLink = new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
  })

  const graphqlLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_API_DOMAIN}/graphql`,
    credentials: 'same-origin',
  })

  const apolloLink: ApolloLink = ApolloLink.split(
    (operation: Operation) =>
      operation.getContext().clientName === CONTENTFUL_CONTEXT_NAME,
    contentfulLink, // <= apollo will send to this if clientName is "contentful"
    graphqlLink // <= otherwise will send to this
  )

  const authMiddleware: ApolloLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      const context: DefaultContext = operation.getContext()
      const isContentFul: boolean =
        context?.clientName === CONTENTFUL_CONTEXT_NAME
      const isPreview: boolean = !!operation.variables?.preview
      const token = isPreview
        ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
        : process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
      const authorization: string = `Bearer ${token}`
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...(isContentFul && { authorization }),
        },
      }))

      return forward(operation)
    }
  )

  const retryLink = new RetryLink({
    delay: {
      initial: 500, // 0.5 seconds
      max: 60000, // 1 minute
      jitter: true, // Whether delays between attempts should be randomized
    },
    attempts: {
      max: 10,
      retryIf: (error) => error.statusCode === 429,
    },
  })

  const apolloClientLink = from([authMiddleware, retryLink, apolloLink])
  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            apolloClientLink,
          ])
        : apolloClientLink,
  })
}

export function ApolloProvider({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  )
}
