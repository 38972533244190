'use client'

import React, { useState } from 'react'
import { FilterProvider as Provider } from './FilterContext.client'
import { IFilter, IFilterState } from './types'

function FilterProvider({ children, unitName }: IFilter) {
  const [selectedItems, setSelectedItems] = useState<
    IFilterState['selectedItems']
  >([])
  const [all, setAll] = useState(true)

  const updateSelectedItems = (item: string) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems((prev) => [...prev, item])
      setAll(false)
    } else {
      const filtered = selectedItems.filter(
        (selectedItem) => selectedItem !== item
      )
      setSelectedItems([...filtered])
      if (filtered.length <= 0) setAll(true)
    }
  }

  const toggleAll = () => {
    setSelectedItems([])
    setAll(true)
  }

  return (
    <Provider
      value={{
        selectedItems,
        updateSelectedItems,
        all,
        toggleAll,
        unitName,
      }}
    >
      {children}
    </Provider>
  )
}

export default FilterProvider
