import { Account, trackAccount as trackSpAccount } from '@generated/snowplow'
import { ISnowplowAccountEvent } from './Snowplow.interface'
import getSharedContext, { trimPayload } from './getSharedContext'

export default function trackAccount(event: ISnowplowAccountEvent) {
  if (typeof window === 'undefined') {
    return
  }

  const accountContext = trimPayload<Account>({
    event_type: event.eventType,
    signup_type: event.signupType,
    audience: event.audience,
    email: event.email,
    submit_success: event.submitSuccess,
    first_name: event.firstName,
    childs_age: event.childAge,
    class_grade_level: event.classGradeLevel,
    age: event.age,
    education_level: event.educationLevel,
    property: event.property,
    topic: event.topic,
    screen_name: event.screenName,
    zip_code: event.zipCode,
  })

  const sharedContext = getSharedContext({
    ...event,
    eventHorizontalPosition: window?.scrollX,
    eventVerticalPosition: window?.scrollY,
  })

  trackSpAccount({
    ...accountContext,
    ...sharedContext,
  })
}
