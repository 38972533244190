/**
 * Initializes the Datadog Real User Monitoring (RUM) in the client-side.
 * This file should be included if using App Router to ensure it runs on the client.
 * Source: https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm#instrument-your-application
 */

'use client'

import { datadogRum } from '@datadog/browser-rum-slim'

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
  site: 'us5.datadoghq.com',
  service: process.env.NEXT_PUBLIC_SERVICE_NAME,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  env: process.env.NEXT_PUBLIC_VERCEL_ENV,
  sessionSampleRate: 50,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
})

export default function Datadog() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
