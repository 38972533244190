'use client'

import { IUser, UserContext } from '@context/UserContext'
import { Loading } from '@fractal/primitives/atoms/Loading'
import { FormControl } from '@fractal/primitives/atoms/forms/FormControl'
import { FormError } from '@fractal/primitives/atoms/forms/FormError'
import { Input } from '@fractal/primitives/atoms/forms/Input'
import { Label } from '@fractal/primitives/atoms/forms/Label'
import capitalize from 'lodash/capitalize'
import React, { useContext, useState } from 'react'
import trackAccount from 'app/components/Snowplow/trackAccount'
import useGlobalContext from 'app/components/GlobalContext/useGlobalContext'
import { useAccountContext } from '../Account.context'

const initialFormState = {
  email: '',
  password: '',

  emailError: '',
  passwordError: '',

  submitted: false,
}

function Signup() {
  const { locale } = useGlobalContext()
  const { signup, updateAccountModal } = useContext(UserContext)
  const [form, setForm] = useState(initialFormState)
  const [isLoading, setIsLoading] = useState(false)
  const [submitErrorText, setSubmitErrorText] = useState('')
  const {
    translation: { signupPage, errorMessage },
    config,
  } = useAccountContext()

  const handleChange = (event) => {
    const { name, value } = event.target
    setForm({
      ...form,
      [name]: value,
      [`${name}Error`]: '',
      submitted: false,
    })
    setSubmitErrorText('')
  }

  const validate = () => {
    let emailError
    let passwordError

    // Regex for email pattern
    const reMail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Regex for password pattern
    const rePass = /(^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_0-9]).*$)/

    if (form.email.trim() === '') {
      emailError = errorMessage?.emailRequired
      setForm((updater) => ({ ...updater, emailError }))
    } else if (!reMail.test(String(form.email).toLowerCase())) {
      emailError = errorMessage?.enterValidEmail
      setForm((updater) => ({ ...updater, emailError }))
    } else {
      setForm((updater) => ({ ...updater, emailError: '' }))
    }

    if (!form.password.trim()) {
      passwordError = errorMessage?.passwordRequired
      setForm((updater) => ({ ...updater, passwordError }))
    } else if (!rePass.test(String(form.password))) {
      passwordError = errorMessage?.passwordRequirements
      setForm((updater) => ({ ...updater, passwordError }))
    } else {
      setForm((updater) => ({ ...updater, passwordError: '' }))
    }

    return !emailError && !passwordError
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const isValid = validate()

    setForm((prev) => ({
      ...prev,
      submitted: true,
    }))

    if (isValid) {
      setIsLoading(true)
      signup?.({ form, language: locale as string }).subscribe({
        next: (user: IUser) => {
          setIsLoading(false)
          if (!config.preferences || !user.is_first_access) {
            updateAccountModal?.(false)
          }
          trackAccount({
            eventType: 'account_creation',
            unitName: 'account_modal',
            unitLocation: 'modal',
            signupType: 'email',
            email: form.email,
            submitSuccess: true,
            componentId: null,
          })
        },
        error: (err) => {
          const formatedErrorCode =
            err.code &&
            err.code
              .split('_')
              .map((value, index) => (index > 0 ? capitalize(value) : value))
              .join('')
          setSubmitErrorText(
            (errorMessage && errorMessage[formatedErrorCode]) ||
              errorMessage?.genericError
          )
          setIsLoading(false)
          trackAccount({
            eventType: 'account_creation',
            unitName: 'account_modal',
            unitLocation: 'modal',
            signupType: 'email',
            email: form.email,
            submitSuccess: false,
            componentId: null,
          })
        },
      })
    }
  }

  return (
    <form
      id='accountSignUpForm'
      onSubmit={handleSubmit}
      data-testid='signup-form'
      noValidate
      className='pl-md-6 pr-md-4'
    >
      {!!config.socialLogin?.length && (
        <p className='text-regular text-foreground-darkblue f-sm-2 text-sm-center text-md-left mb-sm-4'>
          {signupPage?.orSignupWithEmailText}
        </p>
      )}
      <FormControl>
        <Label>{signupPage?.emailLabel}</Label>
        <Input
          id='signUpEmail'
          type='email'
          name='email'
          required
          value={form.email}
          onChange={handleChange}
          disabled={isLoading}
          controlSize='large'
          data-testid='signup-form-email'
          focus={form.submitted && !!form.emailError}
        />
        <FormError>{form.emailError}</FormError>
      </FormControl>

      <FormControl>
        <Label>{signupPage?.passwordLabel}</Label>
        <Input
          id='signUpPassword'
          type='password'
          name='password'
          required
          value={form.password}
          onChange={handleChange}
          disabled={isLoading}
          controlSize='large'
          data-testid='signup-form-password'
          focus={form.submitted && !form.emailError && !!form.passwordError}
        />
        <FormError>
          {form.passwordError && (
            <span
              dangerouslySetInnerHTML={{
                __html: form.passwordError.replace(/\\n/g, '<br />'),
              }}
            />
          )}
        </FormError>
      </FormControl>

      <FormError data-testid='signup-form-error'>{submitErrorText}</FormError>

      <button
        type='submit'
        disabled={isLoading}
        data-testid='signup-form-submit'
        aria-describedby='privacy_policy'
        className='Btn Btn--shadow'
      >
        {signupPage?.createAccountButtonText}
      </button>
      {isLoading && (
        <div className='p-sm-absolute top-sm-0 left-sm-0 w-sm-full h-sm-full bg-background-page-creamLite z-index-2'>
          <Loading />
        </div>
      )}
    </form>
  )
}

export default Signup
