'use client'

import { initialUserState, transformAndDecodeToken } from '@common/utils/user'
import { ILoginParams, ISignupParams, IUser, IUserContext, UserProvider } from '@context/UserContext'
import { useCustomUserData } from '@context/hooks/useCustomUserData'
import auth0 from 'auth0-js'
import axios from 'axios'
import isBefore from 'date-fns/isBefore'
import jwtDecode from 'jwt-decode'
import React, {
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import { Observable, from } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import Cookies from 'universal-cookie'

interface UseAuthContextProps {
  children?: React.ReactNode
  initialUserToken?: string
}

const apiDomain = process.env.NEXT_PUBLIC_API_DOMAIN
const appDomain = process.env.NEXT_PUBLIC_APP_DOMAIN
const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN!
const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!
const auth0Realm = 'Username-Password-Authentication'
const auth0ApiAudience = 'https://www.understood.org'

const UseAuthContext: FunctionComponent<UseAuthContextProps> = ({
  children,
  initialUserToken
}) => {
  const cookies = new Cookies()
  let auth0Client: any = undefined
  const [, setCustomData] = useCustomUserData()
  const [currentPage, setCurrentPage] = useState<IUserContext['currentPage']>(
    'signup'
  )

  if (auth0Domain) {
    auth0Client = new auth0.WebAuth({
      domain: auth0Domain,
      clientID: auth0ClientId,
      audience: auth0ApiAudience,
      redirectUri: auth0Realm,
      contentType: 'application/json',
      scope: 'openid email profile roles',
      responseType: 'token id_token'
    })
  }


  const getInitialUser = () => {
    const idToken = cookies.get('user-token') || initialUserToken
    let newUser = initialUserState as IUser
    if (idToken) {
      newUser = idToken
        ? transformAndDecodeToken(idToken)
        : (initialUserState as IUser)
    }
    newUser = { ...newUser, is_first_access: false }
    return newUser
  }

  const [user, setUser] = useState(getInitialUser())

  useEffect(() => {
    // Set or remove the user to keep consistency among different tabs and services
    const intervalId = setInterval(() => {
      const idToken = cookies.get('user-token')

      if (idToken && !user.is_logged_in) {
        setUserContext(idToken)
      } else if (!idToken && user.is_logged_in) {
        logout(false)
      }

      const accessToken = cookies.get('user-jwt')
      if (accessToken) {
        const { exp } = jwtDecode(accessToken) as any
        const expDate = new Date(exp * 1000)
        if (isBefore(expDate, new Date())) {
          logout(false)
        }
      }
    }, 5000)
    return () => clearInterval(intervalId)
  }, [user])

  const clearUserCookie = () => {
    cookies.remove('user-token')
    cookies.remove('user-jwt')
    setCustomData('roles', undefined)
  }

  const setUserCookie = authResult => {
    const { exp } = jwtDecode(authResult.accessToken) as any
    cookies.set('user-jwt', authResult.accessToken, {
      expires: new Date(exp * 1000),
      path: '/',
      domain: '.understood.org'
    })
    cookies.set('user-token', authResult.idToken, {
      expires: new Date(exp * 1000),
      path: '/',
      domain: '.understood.org'
    })
    if (authResult.classificationData) {
      const roles = Object.keys(authResult.classificationData).filter(
        key => authResult.classificationData[key].is_selected
      )
      if (roles.length) {
        setCustomData('roles', roles)
      }
    }
    return setUserContext(authResult.idToken, !!authResult.isFirstAccess)
  }

  const setUserContext = (idToken = '', isFirstAccess = false) => {
    let newUser = idToken
      ? transformAndDecodeToken(idToken)
      : (initialUserState as IUser)
    newUser = { ...newUser, is_first_access: isFirstAccess }
    setUser(newUser)
    return newUser
  }

  const login = ({ username, password }: ILoginParams) => {
    const params = {
      realm: auth0Realm,
      username,
      password
    }

    const observable = new Observable(subscriber => {
      const callbackFn = (err, authResult) => {
        if (err) {
          subscriber.error(err)
        } else {
          subscriber.next(authResult)
          subscriber.complete()
        }
      }

      auth0Client?.client.login(params, callbackFn)
    })

    return observable.pipe(switchMap(saveUserInDB), map(setUserCookie))
  }

  const signup = ({ form, language }: ISignupParams) => {
    // use the url param to cover amp pages opening the account service in a popup
    const urlParams = new URLSearchParams(window.location.search)
    let referrerUrl = urlParams.get('url')
    if (!referrerUrl) {
      // otherwise get the href to cover react pages
      referrerUrl = window.location.href
    }

    const params = {
      connection: auth0Realm,
      email: form.email,
      password: form.password,
      user_metadata: {
        locale: language?.toLowerCase() || 'en',
        referrer: referrerUrl,
        agreed_to_sign_up_terms: 'true'
      }
    }

    const observable = new Observable(subscriber => {
      const callbackFn = (err, authResult) => {
        if (err) {
          subscriber.error(err)
        } else {
          subscriber.next(authResult)
          subscriber.complete()
        }
      }

      auth0Client?.signupAndAuthorize(params, callbackFn)
    })

    return observable.pipe(switchMap(saveUserInDB), map(setUserCookie))
  }

  const requestPassword = (email: string) => {
    const params = {
      contentType: 'application/json',
      connection: auth0Realm,
      email
    }

    const observable = new Observable(subscriber => {
      const callbackFn = (err, resp) => {
        if (err) {
          subscriber.error(err)
        } else {
          subscriber.complete()
        }
      }

      auth0Client?.changePassword(params, callbackFn)
    })

    return observable
  }

  const socialLogin = (provider: any) => {
    const observable = new Observable(subscriber => {
      const callbackFn = (err, authResult) => {
        if (err) {
          subscriber.error(err)
        } else {
          subscriber.next(authResult)
          subscriber.complete()
        }
      }

      auth0Client?.popup.authorize(
        {
          connection: provider,
          redirectUri: `${appDomain}/social-login-callback`
        },
        callbackFn
      )
    })

    return observable.pipe(switchMap(saveUserInDB), map(setUserCookie))
  }

  const logout = (logoutFromMembers = true) => {
    clearUserCookie()
    setUserContext()

    if (logoutFromMembers) {
      // This code logsout the user using an hidden iframe
      // auth0.js v9 does not support logout without redirecting
      // in other words, calling auth0Client.logout() redirects the page and that is not what we want
      const container = document.getElementById('logout-iframe-container')
      if (window && window.opener && window.opener !== window) {
        const newHref = `${apiDomain}/members/en/logout?next=${window.location.href}`
        window.location.href = newHref
      } else if (container) {
        ReactDOM.unmountComponentAtNode(container)
        const element = React.createElement('iframe', {
          src: `${apiDomain}/members/en/logout?next=none`
        })
        ReactDOM.render(element, container)
      }
    }
  }

  const saveUserInDB = authResult => {
    const newUser = transformAndDecodeToken(authResult.idToken)
    const headers = { Authorization: `Bearer ${authResult.accessToken}` }
    return from(
      axios.get(`${apiDomain}/members/v1/users/${newUser.id}`, { headers })
    ).pipe(
      map((result: any) => ({
        ...authResult,
        isFirstAccess: false,
        classificationData: result.data.classification_data
      })),
      catchError(() =>
        from(
          axios.get(
            `${apiDomain}/members/v1/users/${newUser.id}?ensure-exists=true`,
            { headers }
          )
        ).pipe(
          map((result: any) => ({
            ...authResult,
            isFirstAccess: true,
            classificationData: result.data.classification_data
          }))
        )
      ),
      switchMap(authResult =>
        from(
          axios.post(`${apiDomain}/members/login-clbk`, null, { headers })
        ).pipe(map(() => authResult))
      )
    )
  }

  const isAuthenticated = user.is_logged_in

  const updateUser = (user: IUser) => setUser(user)

  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)

  const updateAccountModal = (bool: boolean) => {
    setIsAccountModalOpen(bool)
    setCurrentPage('signup')
  }

  return (
    <UserProvider
      value={{
        user,
        login,
        socialLogin,
        signup,
        requestPassword,
        logoutUser: logout,
        isAuthenticated,
        updateUser,
        isAccountModalOpen,
        updateAccountModal,
        currentPage,
        setCurrentPage
      }}
    >
      {children}
    </UserProvider>
  )
}

export default UseAuthContext
