'use client'

import { useEffect, useRef } from 'react'
import { enableActivityTracking, newTracker } from '@snowplow/browser-tracker'
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone'
import trackImpression from 'app/components/Snowplow/trackImpression'
import trackPageView from 'app/components/Snowplow/trackPageView'
import { useParams } from 'next/navigation'

const DEFAULT_TRESHOLDS = [0.25, 0.5, 0.75, 1]

export default function TrackPageView() {
  const param = useParams()
  const thresholds = useRef(DEFAULT_TRESHOLDS)

  useEffect(() => {
    newTracker('co', process.env.NEXT_PUBLIC_SNOWPLOW_URL!, {
      appId: 'web-understood',
      plugins: [TimezonePlugin()],
    })

    enableActivityTracking({ heartbeatDelay: 15, minimumVisitLength: 15 })
  }, [])

  useEffect(() => {
    trackPageView()

    const fireEventScroll = () => {
      if (!thresholds.current.length) {
        document.removeEventListener('scroll', fireEventScroll)
        return
      }
      const yScroll = window.scrollY || document.documentElement.scrollTop
      const pageHeight = Math.max(
        document.documentElement.offsetHeight,
        document.documentElement.scrollHeight,
        document.documentElement.clientHeight
      )
      const percentageScrolled =
        (yScroll + document.documentElement.clientHeight) / pageHeight

      // if any of the values are somehow invalid, we add a console log
      // it'll allow datadog to capture this error.
      if (
        yScroll > pageHeight ||
        !Number.isFinite(yScroll) ||
        !Number.isFinite(pageHeight)
      ) {
        // eslint-disable-next-line no-console
        console.error(`Scroll of ${yScroll} on pageHeight of ${pageHeight}`)
      } else {
        const thresholdsReached = thresholds.current.filter(
          (threshold) => threshold <= percentageScrolled
        )

        if (thresholdsReached.length) {
          thresholds.current = thresholds.current.filter(
            (threshold) => threshold > percentageScrolled
          )

          thresholdsReached.forEach(() => {
            trackImpression({
              unitName: 'pixel',
              componentId: null,
              unitLocation: null,
            })
          })
        }
      }
    }

    document.addEventListener('scroll', fireEventScroll)

    return () => {
      document.removeEventListener('scroll', fireEventScroll)
      thresholds.current = DEFAULT_TRESHOLDS
    }
  }, [param])

  return null
}
