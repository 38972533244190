import { IAccount } from './types'

export const defaultProps: IAccount = {
  preferences: [],
  config: {
    openInModal: false,
    socialLogin: ['google', 'facebook', 'apple'],
    signup: true,
    signin: true,
    forgotPassword: true,
    preferences: true,
  },
  signupPage: {
    signupTitle: 'Sign up',
    alreadyAMemberText: 'Already a member?',
    loginText: 'Log in',
    signupWithText: 'Sign up with',
    orSignupWithEmailText: 'Or sign up with your email',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    createAccountButtonText: 'Create account',
    bySigningUp:
      "Review our <a href='#'>privacy policy</a>. You can cancel your account at any time by sending a request to <a href='mailto:info@understood.org'>info@understood.org</a>",
  },
  signinPage: {
    signinTitle: 'Sign in',
    dontHaveAccountText: 'Don’t have an account?',
    createOneText: 'Create one',
    signupWithText: 'Sign in with',
    orSigninWithEmailText: 'Or sign in with your email',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    rememberMeText: 'Remember me',
    forgotPasswordText: 'Forgot password?',
    loginButtonText: 'Log in',
  },
  forgotPasswordPage: {
    forgotPasswordTitle: 'Change password',
    enterYourEmailText:
      'Enter your email below and we’ll send you a link where you can reset your password',
    emailLabel: 'Email',
    submitButtonText: 'Submit',
    checkYourInboxText:
      'Check your inbox and follow instructions to reset your password.',
    didntGetTheEmailText: 'Didn’t get the email?',
    resendLinkText: 'Resend link',
    signinText: 'Sign in',
  },
  preferencesPage: {
    pageTitle: 'Tell us what interests you',
    pageDescription:
      'Your preferences are now saved! Tell us more about your interests so we can connect you to the right content.',
    classificationsTitle: 'I am...',
    classificationsDescription:
      'This information is private and totally optional.',
    classifications: [
      {
        key: 'parent',
        label: 'a parent or caregiver',
        conditionalFields: [
          {
            key: 'age',
            label: 'Child’s age',
            subLabel:
              'If you have more than one child, please note the age of your youngest.',
            values: [
              { key: '1', label: '1' },
              { key: '2', label: '2' },
              { key: '3', label: '3' },
              { key: '4', label: '4' },
              { key: '5', label: '5' },
              { key: '6', label: '6' },
              { key: '7', label: '7' },
              { key: '8', label: '8' },
              { key: '9', label: '9' },
              { key: '10', label: '10' },
              { key: '11', label: '11' },
              { key: '12', label: '12' },
              { key: '13', label: '13' },
              { key: '14', label: '14' },
              { key: '15', label: '15' },
              { key: '16', label: '16' },
              { key: '17', label: '17' },
              { key: '18+', label: '18+' },
            ],
          },
        ],
      },
      {
        key: 'educator',
        label: 'an educator',
        conditionalFields: [
          {
            key: 'grade_level',
            label: 'Grade level you teach',
            values: [
              { key: 'INFANT_TODDLER', label: 'Infant/toddler (0-2)' },
              { key: 'PRESCHOOL', label: 'Preschool' },
              { key: 'GRADE_SCHOOL', label: 'Grade school' },
              { key: 'MIDDLE_SCHOOL', label: 'Middle school' },
              { key: 'HIGH_SCHOOL', label: 'High school' },
              { key: 'COLLEGE', label: 'College' },
              {
                key: 'TRADE_SCHOOL',
                label: 'Trade or other professional school',
              },
              { key: 'GRAD_SCHOOL', label: 'Graduate school' },
              { key: 'NO_ANSWER', label: 'Prefer not to say' },
            ],
          },
        ],
      },
      {
        key: 'individual',
        label: 'here for me',
        conditionalFields: [
          {
            key: 'age',
            label: 'Your age',
            subLabel:
              'You must be 13 or older to create an Understood account.',
            values: [
              { key: '13-17', label: '13-17' },
              { key: '18-25', label: '18-25' },
              { key: '26-35', label: '26-35' },
              { key: '36-50', label: '36-50' },
              { key: '51-64', label: '51-64' },
              { key: '65+', label: '65+' },
            ],
          },
          {
            key: 'ed_level',
            label: 'Your education level',
            values: [
              { key: 'SOME_HIGH_SCHOOL', label: 'Some high school' },
              { key: 'HIGH_SCHOOL_GRAD', label: 'High school graduate' },
              { key: 'SOME_COLLEGE', label: 'Some college' },
              { key: 'COLLEGE_GRAD', label: 'College graduate' },
              { key: 'GRAD_SCHOOL', label: 'Graduate school' },
              { key: 'NO_ANSWER', label: 'Prefer not to say' },
            ],
          },
        ],
      },
      {
        key: 'other',
        label: 'other',
        conditionalFields: [
          {
            key: 'age',
            label: 'Your age',
            subLabel:
              'You must be 13 or older to create an Understood account.',
            values: [
              { key: '13-17', label: '13-17' },
              { key: '18-25', label: '18-25' },
              { key: '26-35', label: '26-35' },
              { key: '36-50', label: '36-50' },
              { key: '51-64', label: '51-64' },
              { key: '65+', label: '65+' },
            ],
          },
          {
            key: 'ed_level',
            label: 'Your education level',
            values: [
              { key: 'SOME_HIGH_SCHOOL', label: 'Some high school' },
              { key: 'HIGH_SCHOOL_GRAD', label: 'High school graduate' },
              { key: 'SOME_COLLEGE', label: 'Some college' },
              { key: 'COLLEGE_GRAD', label: 'College graduate' },
              { key: 'GRAD_SCHOOL', label: 'Graduate school' },
              { key: 'NO_ANSWER', label: 'Prefer not to say' },
            ],
          },
        ],
      },
    ],
    tagsTitle: 'Follow topics',
    saveButton: 'Save selections',
    skipText: 'Skip this',
  },
  errorMessage: {
    emailRequired: 'Email required',
    enterValidEmail: 'Enter a valid email',
    passwordRequired: 'Password required',
    passwordRequirements:
      'Password must:\n Be at least eight characters long\nContain uppercase and lowercase letters\nInclude numbers and/or special characters',
    userExists: 'The user already exists',
    invalidGrant: 'Wrong email or password.',
    tooManyAttempts:
      "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.",
    genericError: 'Something went wrong. Please try again later.',
    preferenceError:
      'Oops!\nSorry — we’re having an issue saving your selections. Our team is working to fix this as fast as we can. Please try again later.',
  },
}

export const tagsMock = [
  {
    id: '1',
    type: '',
    name: '',
    selected: true,
    title: 'Trouble with focus',
  },
  {
    id: '2',
    type: '',
    name: '',
    selected: true,
    title: 'Communication',
  },
  {
    id: '3',
    type: '',
    name: '',
    selected: true,
    title: 'Focus and organisation',
  },
  {
    id: '4',
    type: '',
    name: '',
    selected: true,
    title: 'Self-control',
  },
  {
    id: '5',
    type: '',
    name: '',
    selected: true,
    title: 'Anger and frustration',
  },
  {
    id: '6',
    type: '',
    name: '',
    selected: true,
    title: 'Dyslexia',
  },
  {
    id: '7',
    type: '',
    name: '',
    selected: true,
    title: 'ADHD',
  },
  {
    id: '8',
    type: '',
    name: '',
    selected: true,
    title: 'Trouble with focus',
  },
  {
    id: '9',
    type: '',
    name: '',
    selected: true,
    title: 'Communication',
  },
  {
    id: '10',
    type: '',
    name: '',
    selected: true,
    title: 'Focus and organisation',
  },
  {
    id: '11',
    type: '',
    name: '',
    selected: true,
    title: 'Self-control',
  },
  {
    id: '12',
    type: '',
    name: '',
    selected: true,
    title: 'Anger and frustration',
  },
  {
    id: '13',
    type: '',
    name: '',
    selected: true,
    title: 'Dyslexia',
  },
  {
    id: '14',
    type: '',
    name: '',
    selected: true,
    title: 'ADHD',
  },
]
