'use client'

import { UserContext } from '@context/UserContext'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import { IAccount } from './types'
import { UseAccountProvider, useAccountContext } from './Account.context'
import { defaultProps } from './Account.data'
import Logout from './forms/Logout'
import SocialLogin from './forms/SocialLogin'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import PreferencesPage from './pages/PreferencesPage'
import SigninPage from './pages/SigninPage'
import SignupPage from './pages/SignupPage'
import styles from './Account.module.scss'

function AccountModal({ children }: any) {
  const { user, isAccountModalOpen, updateAccountModal } =
    useContext(UserContext)
  const { requestPageChange, config } = useAccountContext()

  useEffect(() => {
    if (!isAccountModalOpen) {
      requestPageChange('signup')
    }
  }, [isAccountModalOpen])

  useEffect(() => {
    if (user.is_logged_in && (!user.is_first_access || !config.preferences)) {
      updateAccountModal?.(false)
    }
  }, [user])

  function onModalClose() {
    trackEngagement({
      eventType: 'dismiss',
      unitName: 'account_modal',
      unitLocation: 'modal',
      componentId: null,
    })
    updateAccountModal?.(false)
  }

  return (
    <div id='account-modal'>
      <Modal
        testId='account-react-modal-component'
        isOpen={isAccountModalOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onRequestClose={onModalClose}
        className={`p-sm-absolute bg-background-page-creamLite w-sm-full h-sm-full rounded-md-1 ${styles.modal}`}
        style={{
          overlay: {
            zIndex: 105,
          },
        }}
      >
        <div>
          <TrackImpression
            unitName='account_modal'
            unitLocation='modal'
            componentId={null}
          />
          <button
            type='button'
            aria-label='Close authentication'
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onClick={onModalClose}
            className='Btn-link p-sm-absolute top-sm-0 right-sm-0 z-index-100 text-foreground-darkblue'
          >
            <SVGclose className='vicon m-sm-4' />
          </button>
          {children}
        </div>
      </Modal>
      <Logout />
    </div>
  )
}

function Account(props: IAccount) {
  const { user, updateAccountModal } = useContext(UserContext)
  const [visiblePage, setVisiblePage] = useState('')
  const previousIsLoggedIn = useRef(user.is_logged_in)
  const [ariaStatus, setAriaStatus] = useState('')
  const [isOpenedInPopup, setIsOpenedInPopup] = useState(false)

  const resolvedProps = { ...defaultProps, ...props }

  useEffect(() => {
    if (user.is_logged_in !== previousIsLoggedIn.current) {
      setAriaStatus(
        `you have successfully logged ${user.is_logged_in ? 'in' : 'out'}`
      )
      previousIsLoggedIn.current = user.is_logged_in
    }
  }, [user])

  useEffect(() => {
    if (typeof window === 'object') {
      setIsOpenedInPopup(window.opener && window.opener !== window)
    }
  }, [])

  const closePopupWindow = () => {
    trackEngagement({
      eventType: 'dismiss',
      unitName: 'account_modal',
      unitLocation: 'modal',
      componentId: null,
    })
    if (typeof window === 'object') {
      window?.close()
    }
  }

  return (
    <UseAccountProvider accountProps={resolvedProps}>
      {({ translation, config, currentPage }) => {
        let WrapperTag = React.Fragment as any
        const { preferences } = resolvedProps
        if (config.openInModal) {
          WrapperTag = AccountModal as any
        } else {
          // setting the modal to true, even though the account won't open using it
          // makes it easier to close the popup window AMP needs to authenticate
          updateAccountModal?.(true)
        }

        const pages = ['signup', 'signin', 'forgotPassword'].filter(
          (page) => config[page]
        )

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          setVisiblePage('')
          const timeout = setTimeout(() => {
            setVisiblePage(currentPage)
          }, 300)
          return () => clearTimeout(timeout)
        }, [currentPage])

        return (
          <>
            <WrapperTag>
              <div
                data-testid='account-container'
                className='w-sm-full h-sm-full p-sm-relative o-sm-x-hidden'
              >
                {!config.openInModal && isOpenedInPopup && (
                  <button
                    type='button'
                    aria-label='Close authentication'
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    onClick={closePopupWindow}
                    className='Btn-link p-sm-absolute top-sm-0 right-sm-0 z-index-100 text-foreground-darkblue'
                  >
                    <SVGclose className='vicon m-sm-4' />
                  </button>
                )}
                {!user.is_logged_in && (
                  <div
                    className='h-sm-full d-sm-flex'
                    style={{
                      transition: '0.3s ease-in-out',
                      width: `${(pages.length || 1) * 100}%`,
                      transform: `translateX(-${
                        pages.indexOf(currentPage) * (100 / pages.length)
                      }%)`,
                    }}
                  >
                    {config.signup && (
                      <SignupPage
                        id='signup'
                        style={{
                          visibility:
                            (!visiblePage && 'visible') ||
                            (visiblePage === 'signup' ? 'visible' : 'hidden'),
                        }}
                      />
                    )}
                    {config.signin && (
                      <SigninPage
                        id='signin'
                        style={{
                          visibility:
                            (!visiblePage && 'visible') ||
                            (visiblePage === 'signin' ? 'visible' : 'hidden'),
                        }}
                      />
                    )}
                    {config.forgotPassword && (
                      <ForgotPasswordPage
                        id='forgotPassword'
                        style={{
                          visibility:
                            (!visiblePage && 'visible') ||
                            (visiblePage === 'forgotPassword'
                              ? 'visible'
                              : 'hidden'),
                        }}
                      />
                    )}
                    {!config.signup &&
                      !config.signin &&
                      !config.forgotPassword &&
                      config.socialLogin && (
                        <div
                          className={`m-sm-1 mb-sm-3 w-sm-full ${styles.socialContainer}`}
                        >
                          {config.socialLogin.map((socialProvider) => (
                            <SocialLogin
                              key={socialProvider}
                              provider={socialProvider as any}
                              loginWithText={
                                translation.signinPage?.signupWithText
                              }
                            />
                          ))}
                        </div>
                      )}
                  </div>
                )}
                {config.preferences &&
                  user.is_logged_in &&
                  user.is_first_access && (
                    <div>
                      <PreferencesPage preferences={preferences} />
                    </div>
                  )}
                {!config.openInModal && <Logout />}
              </div>
            </WrapperTag>

            <div role='status' className='sr-only'>
              {ariaStatus}
            </div>
          </>
        )
      }}
    </UseAccountProvider>
  )
}

export default Account
