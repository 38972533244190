'use client'

import SVGclose from '@fractal/primitives/SVGs/icons/close'
import React, { useContext, useEffect, useState } from 'react'
import ButtonToggleLink from '@fractal/primitives/atoms/Button/ButtonToggleLink'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import FilterContext from './FilterContext.client'
import { IFilterButtonToggle } from './types'
import styles from './Filter.module.scss'

function FilterButtonToggle({
  tags,
  modal,
  modalProps,
  selectedTags,
  onButtonToggle,
  moreTagsText,
  visibleTagCount,
  nbColumns = 2,
  maxNumOfVisits = 2,
}: IFilterButtonToggle) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tagsToShow, setTagsToShow] = useState<IFilterButtonToggle['tags']>(
    tags.slice(0, visibleTagCount)
  )
  const { selectedItems, updateSelectedItems, unitName } =
    useContext(FilterContext)

  function initialTagsUpdate(selectedPill) {
    const pillArray =
      typeof selectedPill === 'object' ? selectedPill : JSON.parse(selectedPill)
    pillArray
      ?.filter((pill) => selectedItems.indexOf(pill) === -1)
      ?.forEach((pill) => updateSelectedItems(pill))
  }

  useEffect(() => {
    setTagsToShow(tags.slice(0, visibleTagCount))
  }, [tags, visibleTagCount])

  useEffect(() => {
    if (selectedTags && selectedTags.length) {
      initialTagsUpdate(selectedTags)
    }
  }, [selectedTags])

  useEffect(() => {
    if (onButtonToggle) onButtonToggle(selectedItems)
  }, [selectedItems])

  const onPillSelected = ({ target }) => {
    updateSelectedItems(target.value)
  }

  const onShowMoreCLicked = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  function isSelected(tagArgs) {
    if (tagArgs || tagArgs.id || tagArgs.sys?.id) {
      return selectedItems.includes(tagArgs.id || tagArgs.sys?.id)
    }
    return false
  }

  return (
    <div className='d-sm-flex flex-sm-column w-sm-full o-sm-auto'>
      <div
        className='grid-md grid-gap-row-none grid-sm-12'
        style={{
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(${Math.ceil(
            tagsToShow.length / nbColumns
          )}, auto)`,
          gridAutoColumns: new Array(nbColumns).fill('1fr').join(' '),
          gridTemplateColumns: new Array(nbColumns).fill('1fr').join(' '),
        }}
      >
        {tagsToShow?.map((tag) => (
          <div key={`tagoption-${tag.id || tag.sys?.id}`}>
            <ButtonToggleLink
              value={tag.id || tag.sys?.id || ''}
              text={tag.title}
              isSelected={isSelected(tag)}
              onToggle={onPillSelected}
              dataTestid={`filter-toggle-button-${tag.id || tag.sys?.id}`}
            />
          </div>
        ))}
      </div>
      {!modal && visibleTagCount && tags.length > visibleTagCount && (
        <TrackClick
          as='button'
          type='button'
          onClick={onShowMoreCLicked}
          className={`Btn-link text-underline primary-link f-sm-2 mx-sm-2 mt-sm-5 flex-sm-self-left flex-md-self-center ${styles.moreButton}`}
          isEngagementEvent={false}
          trackProps={{
            clickType: 'button',
            buttonName: 'see_more_topics',
            unitName,
            unitLocation: 'inline',
            componentId: null,
          }}
        >
          {moreTagsText}
        </TrackClick>
      )}
      {isModalOpen && (
        <div className='z-index-3 p-sm-fixed left-sm-0 top-sm-0 w-sm-full h-sm-full'>
          <div className='d-sm-flex flex-md-align-center h-sm-full p-sm-relative bg-static-black-50'>
            <div className='container-md container-lg grid-md h-sm-full w-sm-full'>
              <div
                className={`p-md-relative bg-background-low-white grid-align-self-sm-center grid-col-sm-12
                  grid-start-md-2 grid-col-md-10 p-sm-5 p-md-6 rounded-md-1 d-sm-flex flex-sm-column o-sm-hidden h-sm-full`}
              >
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                  type='button'
                  onClick={() => setIsModalOpen(false)}
                  className='Btn-link p-sm-absolute p-sm-4 top-sm-2 right-sm-2'
                >
                  <SVGclose className='vicon m-sm-4' />
                </button>
                <h2 className='f-sm-5 f-md-6 pb-sm-4 d-sm-block'>
                  {modalProps?.modalTitle}
                </h2>
                <h3
                  className='f-sm-3 text-regular lh-default mb-sm-3 mb-md-5'
                  id='form-modal-0001-title'
                >
                  {modalProps?.modalDescription}
                </h3>
                <FilterButtonToggle
                  tags={tags}
                  selectedTags={selectedTags}
                  maxNumOfVisits={maxNumOfVisits}
                  modal
                />
                <div className='mt-sm-5 d-sm-flex flex-sm-column flex-sm-align-center'>
                  <button
                    type='button'
                    onClick={(e) => {
                      setIsModalOpen(false)
                      modalProps?.onSubmit?.(e, selectedItems)
                    }}
                    className='Btn Btn--shadow text-wrap flex-sm-self-center'
                  >
                    {modalProps?.modalButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterButtonToggle
