import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAccountContext } from '../Account.context'
import Signup from '../forms/Signup'
import SocialLogin from '../forms/SocialLogin'
import styles from '../Account.module.scss'

function SignupPage(props) {
  const {
    requestPageChange,
    translation: { signupPage },
    config,
  } = useAccountContext()
  const [ref, inView, entry] = useInView({
    triggerOnce: false,
    threshold: 0,
  })

  useEffect(() => {
    if (inView && entry?.target) {
      ;(entry.target as HTMLElement).focus()
    }
  }, [inView])

  return (
    <div
      {...props}
      data-testid='signup-page'
      className='p-sm-relative p-sm-5 w-sm-full d-sm-flex flex-sm-column p-md-6'
    >
      <div className='d-md-flex flex-md-align-baseline'>
        <h1
          ref={ref}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className='f-sm-6 f-md-7 text-bold text-foreground-darkblue mb-sm-3 mx-md-5'
        >
          {signupPage?.signupTitle}
        </h1>
        {config.signin && (
          <p className='text-regular text-foreground-darkblue f-sm-2 text-left mb-sm-0 flex-sm'>
            {signupPage?.alreadyAMemberText}{' '}
            <button
              type='button'
              data-testid='signin-link'
              onClick={() => requestPageChange('signin', signupPage?.loginText)}
              className={`Btn-link text-foreground-darkblue text-bold text-underline ${styles.pageLink}`}
            >
              {signupPage?.loginText}
            </button>
          </p>
        )}
      </div>
      <div className='d-sm-flex flex-sm-wrap'>
        <div className={styles.columnMd6}>
          {!!config.socialLogin?.length && (
            <div
              className={`py-sm-5 px-sm-4 mb-sm-3 w-sm-full pt-md-6 pr-md-7 pl-md-4 h-md-full  
              ${styles.socialContainer} ${styles.nonStandaloneSocial}`}
            >
              {config.socialLogin?.map((socialProvider) => (
                <SocialLogin
                  key={socialProvider}
                  provider={socialProvider as any}
                  loginWithText={signupPage?.signupWithText}
                  data-testid={`signup-social-${socialProvider}`}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={
            config.socialLogin?.length ? styles.columnMd6 : styles.columnMd12
          }
        >
          <Signup />
        </div>
      </div>
      <p
        id='privacy_policy'
        className={`text-foreground-darkblue f-sm-1 text-regular m-sm-0 pt-sm-5 text-md-left ${styles.privacyPolicy}`}
        dangerouslySetInnerHTML={{ __html: signupPage?.bySigningUp || '' }}
      />
    </div>
  )
}

export default SignupPage
